<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					SUPPORT
				</div>
				<div class="cen-msg">
					SZZT Payment Support Center (NPSC) is dedicated to providing our
					<br />
					customers with quality services and
					support. Our customers have access to
					<br />
					a team of specialists with extensive product knowledge and deep
					<br />
					understanding of best practices in the field.
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_top">
					<div class="top-title">
						Support
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_2_1_bot">
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/support/sp1.png" alt="" />
						</div>
						<div class="item-name">
							Training
						</div>
						<div class="item-content">
							SZZT offers a variety of technical training including software development and hardware
							maintenance to our international partners to ensure they know our products as well as we do.
						</div>
					</div>
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/support/sp2.png" alt="" />
						</div>
						<div class="item-name">
							Technical Support
						</div>
						<div class="item-content">
							Application development On-site technical support Remote technical support Consultancy
							services RFP preparation support
						</div>
					</div>
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/support/sp3.png" alt="" />
						</div>
						<div class="item-name">
							Warranty
						</div>
						<div class="item-content">
							SZZT offers a standard 1-year warranty on all products and extended warranty rates if
							required.
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_3">
			<div class="page_3_1">
				<div class="page_3_1_top">
					<div class="top-title">
						Development Kit
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_3_1_bot">
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/support/dk.png" alt="" />
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Equipment management module
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Printer module
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Magnetic stripe cards
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Contact card
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								ID card identification
							</div>
						</div>
					</div>
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/support/dk.png" alt="" />
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Password keyboard
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Contactless card
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								EMV kernel
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Serial port
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								LED
							</div>
						</div>
					</div>
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/support/dk.png" alt="" />
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Network management
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Camera scan code
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								System Management
							</div>
						</div>
						<div class="item-list">
							<div class="list-icon">

							</div>
							<div class="list-msg">
								Barcode
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_4">
			<div class="page_4_1">
				<div class="page_4_1_top">
					<div class="top-title">
						Multi-modal product customization
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_4_1_bot">
					<div class="page_4_item">
						<div class="page_4_list">
							<img src="../../assets/images/support/mc1.jpg" alt="" />
							<div class="list-content">
								<div class="title">
									CBU
								</div>
								<div class="text">
									The whole machine is directly exported to the country where the customer is located,
									without any processing or assembly.
								</div>
							</div>
						</div>
					</div>
					<div class="page_4_item">
						<div class="page_4_list">
							<div class="list-content">
								<div class="title">
									SKD
								</div>
								<div class="text">
									Shipped as a semi-finished product, assembled by the customer at the factory in the
									country where they are located. This method is suitable for some countries where the
									import tariff of SKD is lower than that of CBU
								</div>
							</div>
							<img src="../../assets/images/support/mc2.jpg" alt="" />

						</div>
					</div>
					<div class="page_4_item">
						<div class="page_4_list">
							<img src="../../assets/images/support/mc3.jpg" alt="" />
							<div class="list-content">
								<div class="title">
									CKD
								</div>
								<div class="text">
									It is shipped in the form of all parts, and the customer's own patch and assembly in
									the factory in the country.
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_5">
			<div class="page_5_1">
				<div class="page_5_1_top">
					<div class="top-title">
						After-sales Services
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_5_1_bot">
					<div class="page_5_left">
					</div>
					<div class="page_5_right">
					</div>
				</div>


			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {

			screenWidth: {
				handler: function(val) {
					// console.log("当前屏幕尺寸--" + val)
					// console.log(this.list_status)
					if (val != null && val < 742) {
						this.list_status = false
					} else {
						this.list_status = true
					}
					// console.log(this.list_status)
				},
				immediate: true,
				deep: true
			},
		},

		mounted() {
			window.addEventListener("scroll", this.watchScroll); // 滚动吸顶
			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth // 监听屏幕宽度
				})()
			}

		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				acctive_img: require("../../assets/images/about/box_1.png"),
				show_top: false,
				data_list: [{
						id: 0,
						img: require("../../assets/images/about/box_1.png"),
						acctive: true
					}, {
						id: 1,
						img: require("../../assets/images/about/box_2.png"),
						acctive: false
					},
					{
						id: 2,
						img: require("../../assets/images/about/box_3.png"),
						acctive: false
					},
					{
						id: 3,
						img: require("../../assets/images/about/box_4.png"),
						acctive: false
					},

				],
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			// 鼠标移入-导航
			enter_img(item) {
				this.acctive_img = item.img;
				for (var i = 0; i < this.data_list.length; i++) {
					if (item.id == this.data_list[i].id) {
						this.data_list[i].acctive = true;
					} else {
						this.data_list[i].acctive = false;
					}
				}
			},
			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
				} else {
					this.navBarFixed = false;
				}
			},
			go_index() {
				window.location.href = "/";
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 380px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/support/support_bg.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.cen-text {
			margin-left: 15%;
			font-size: 60px;
			margin-top: 120px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}


		.cen-msg {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			line-height: 30px;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
		.page_2 {
			background: #FFFFFF;
			padding-bottom: 100px;
	
			.page_2_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_2_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_2_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_2_item {
					width: 400px;
					height: 270px;
					display: flex;
					flex-direction: column;
					background-color: #F8F8F8;
					border-radius: 5px;
	
					.top_img {
						width: 100%;
						height: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
	
						img {
							object-fit: cover;
						}
					}
	
					.item-name {
						width: 82%;
						margin: 0 auto;
						margin-bottom: 10px;
						font-size: 24px;
						color: #333333;
						text-align: center;
					}
	
					.item-content {
						width: 90%;
						margin: 0 auto;
						text-align: center;
						font-size: 14px;
						color: #666666;
					}
				}
	
	
	
			}
	
		}
	
		.page_3 {
			background: #ebf5fb;
			padding-bottom: 150px;
	
			.page_3_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_3_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_3_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_3_item {
					width: 400px;
					height: 360px;
					display: flex;
					flex-direction: column;
					background-color: #FFFFFF;
					border-radius: 5px;
	
					.top_img {
						width: 100%;
						height: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
	
						img {
							object-fit: cover;
						}
					}
	
					.item-list {
						display: flex;
						align-items: center;
						margin: 5px 0 15px 20px;
	
						.list-icon {
							width: 15px;
							height: 15px;
							border-radius: 50%;
							background-color: #0080CC;
							margin-right: 10px;
						}
	
						.list-msg {
							font-size: 20px;
							color: #666666;
						}
					}
	
	
				}
	
	
	
			}
	
		}
	
		.page_4 {
			background: #FFFFFF;
			padding-bottom: 100px;
	
			.page_4_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_4_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_4_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
	
				.page_4_item {
					width: 100%;
					display: flex;
					flex-direction: column;
					background-color: #F8F8F8;
					border-radius: 5px;
					margin-bottom: 100px;
	
					.page_4_list {
						display: flex;
						justify-content: space-between;
						img {
							width: 640px;
							height: 530px;
							object-fit: cover;
						}
	
						.list-content {
							width: 640px;
							height: 100%;
							padding-left: 50px;
	
							.title {
								margin-top: 100px;
								color: #4d4d4d;
								font-size: 60px;
							}
	
							.text {
								margin-top: 30px;
								width: 520px;
								font-size: 24px;
								color: #666666;
								line-height: 40px;
							}
						}
					}
	
	
				}
	
	
	
			}
	
		}
	
	
		.page_5 {
			width: 100%;
			min-width: 1360px;
			padding-top: 100px;
			padding-bottom: 200px;
			background: #FAFAFA;
	
	
			.page_5_1 {
				width: 1360px;
				margin: 0 auto;
	
	
				.page_5_1_top {
					width: 100%;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
	
					.top-title {
						color: #000000;
						font-size: 48px;
					}
					
					.bot-line {
						margin-top: 20px;
						width: 127px;
						height: 4px;
						background-color: #0081CC;
					}
	
				}
	
				.page_5_1_bot {
					width: 92%;
					margin: 0 auto;
					margin-top: 80px;
					display: flex;
					align-items: center;
	
	
					.page_5_left {
						width: 440px;
						height: 400px;
						background-image: url("../../assets/images/support/as.jpg");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
						border-radius: 5px;
	
					}
	
					.page_5_right {
						width: 807px;
						height: 400px;
						border: 1px solid #e6e6e6;
						background-image: url("../../assets/images/support/sp4.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
					}
				}
			}
		}
	
	
	
	
	
</style>